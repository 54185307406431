.component {
    width: 100vw;
    margin: var(--gap) auto calc(4 * var(--gap));
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (--min-sm) {
        flex-flow: row nowrap;
    }
}

.link {
    flex-basis: calc(100% / 3);
    display: flex;
    position: relative;
    color: hsl(var(--text-white));

    &:focus,
    &:hover {

        &::before {
            opacity: 1;
            transform: translateY(-10px);
            transition-duration: 0.6s;
        }

        .image {
            opacity: 1;
            transition-duration: 0.6s;
        }

        .heading {
            transform: translate(-50%, -6px);
        }
    }

    &:nth-child(1) {

        &::before {
            background: hsl(var(--color-red));
        }
    }

    &:nth-child(2) {

        &::before {
            background: hsl(var(--color-white));
        }
    }

    &:nth-child(3) {

        &::before {
            background: hsl(var(--color-brown));
        }

        .heading {
            max-width: 8ch;
        }
    }

    &::before {
        width: 100%;
        height: 10px;
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        opacity: 0;
        transform: translateY(0);
        transition-property: transform, opacity;
        transition-duration: 0.4s;
        transition-timing-function: var(--transition-timing);
    }
}

.linkHeading {
    position: absolute;
    top: calc((30% - 60px) / 2);
    left: 50%;
    z-index: 1;
    font-size: 2.75rem;
    font-weight: bold;
    text-transform: uppercase;
    transform: translateX(-50%);

    @media (--min-sm) {
        top: calc((30% - 20px) / 2);
        font-size: 1.5rem;
    }

    @media (--min-md) {
        top: calc((30% - 55px) / 2);
        font-size: 2.5rem;
    }
}

.image {
    width: 100% !important;
    opacity: 0.2;
    transition: opacity 0.4s var(--transition-timing);
}

.logotyp {
    width: 80%;
    position: absolute;
    top: calc((30% - 40px) / 2);
    left: 50%;
    z-index: 1;
    transform: translateX(-50%);
}

.heading {
    position: absolute;
    top: 30%;
    left: 50%;
    color: hsl(var(--text-white));
    font-size: 1.75rem;
    line-height: 1.4;
    text-transform: uppercase;
    transform: translateX(-50%);
    transition: transform 0.6s ease-in-out;

    @media (--min-md) {
        font-size: 2rem;
    }

    @media (--min-lg) {
        font-size: 2.25rem;
    }
}

/* stylelint-disable-next-line a11y/no-spread-text */
.subheading {
    max-width: 90ch;
    margin: 0 auto var(--gap);
    color: hsl(var(--color-gray-lighten-3));
    font-size: 1.1rem;
    text-align: center;
}

.socials {
    margin: 0 auto calc(2 * var(--gap));
}

.social {
    margin: 0 calc(0.5 * var(--gap));
}

.copyright {
    padding-bottom: var(--gap);
    font-size: 0.8rem;
    text-align: center;
}
